/* @media (max-width: 1521px) {
    .container {
        width: 1300px;
    }

    .criptaName {
        font-size: 28px;
    }

    .price {
        font-size: 30px;
    }


}



@media (max-width: 1400px) {
    .container {
        width: 1300px;
    }

} */

@media (max-width: 421px) {
  .container {
    width: 100%;
    margin: 0;
    padding: 0px 20px;
  }

  .mediaBlock h1 {
    font-size: 19px;
    text-align: center;
  }
  .mainBlock {
    height: 100vh;
    padding: 60px 0 0;
    position: relative;
  }
  .big br {
    display: none;
  }

  .mediaBlock p.big {
    font-size: 50px;
    line-height: 85px;
    margin: 0;
    text-align: center;
  }
  .mediaBlock p.mini {
    margin: 16px 0px 30px 0px;
    text-align: center;
  }
  .block2 {
    padding: 30px 0px;
  }
  .block2 h2 br,
  .block3 h2 br {
    display: none;
  }
  .block2 h2,
  .block3 h2 {
    text-align: center;
  }
  .tre .media {
    height: 230px;
  }
  .block4 {
    background-size: cover;
    background-position: right;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .mediaBlock .content {
    width: 100%;
    padding-top: 48px;
  }

  .mediaBlock .media {
    position: absolute;
    bottom: -4px;
    width: 90%;
  }

  .media_menu {
    display: block;
  }

  .mobileBlock.flex {
    display: none;
  }

  .well .container {
    flex-direction: column;
  }

  .ElWell {
  }

  h2 {
    font-size: 23px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  .listServices .service {
    width: 100%;
    margin: 10px 0px;
  }

  h3 {
    font-size: 24px;
    text-align: center;
  }

  .tre p {
    font-size: 25px;
    line-height: 28px;
  }

  .listServices .service p {
    font-size: 18px;
  }

  .tre_show {
    width: 100%;
    padding: 20px 0px;
  }

  .interes.flex.top {
    flex-wrap: wrap;
  }

  .block4 .media {
    display: none;
  }

  .block4 .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contentLeft br,
  .contentRight br {
    display: none;
  }
  .block4 h2 {
    text-align: center;
    font-size: 47px;
  }

  .trubka {
    width: 114px;
  }

  .block4 .content p {
    margin: 20px 20px;
    font-size: 18px;
  }

  .block5 h2 {
    font-size: 47px;
    text-align: center;

    margin-bottom: 25px;
  }

  .block5 .content {
    width: 100%;
  }

  .block5 .flex {
    flex-direction: column;
  }

  .block5 .bigText {
    font-size: 92px;
    line-height: 114px;
  }

  .block5 .media {
    width: 50%;
    position: absolute;
    bottom: 0;
    right: -41px;
  }

  .men,
  .comments h2 {
    display: none;
  }

  .block6 .flex {
    flex-direction: column;
    /* flex-direction: column-reverse; */
  }
  .message h3 + p {
    font-size: 15px;
    line-height: 25px;
  }
  .message {
    padding: 20px;
    height: auto;
  }

  .block6 .contentLeft {
    width: 100%;
    text-align: start;
  }
  .contentLeft h2 {
    font-size: 35px;
  }

  .block6 .contentRight {
    padding-top: 200px;
    width: 100%;
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .contentRight_info_box {
    left: 0;
    bottom: 0;
  }
  .block6 p {
    margin-bottom: 19px;
  }

  .contentRight h3 {
    font-size: 35px;
    text-align: center;
    left: 0;
  }

  .block6 {
    background-size: cover;
    background-position: top center;
    padding: 50px 0px 50px 0px;
  }

  p {
    font-size: 17px;
    line-height: 22px;
  }

  .comments_box {
    flex-direction: column;
    gap: 40px;
  }

  .message_shedow {
    width: 100%;
  }

  .footer .flex {
    flex-direction: column;
  }

  .logo + div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 34px 0px;
  }

  .footer .logo {
    width: 130px;
  }

  .block5 {
    overflow: hidden;
  }

  .header .logo img {
    width: 70px;
  }

  .media_menu_link a {
    font-size: 31px;
  }
  .block6 .button {
    font-size: 14px;
  }
  .price {
    text-align: center;
  }
  .criptaName {
    text-align: center;
  }
  .ElWell_img + div {
    width: 100%;
  }
  .button {
    padding: 31px 27px;
    font-size: 19px;
  }
  .block4 h2,
  .block5 h2 {
    padding: 20px 7px;
    font-size: 35px;
  }
  .block4 h2 br {
    display: none;
  }
  .block5 p {
    font-size: 29px;
    line-height: 104%;
    text-align: center;
    margin-bottom: 70px;
  }
}
@media (max-width: 376px) {
  body {
    min-width: 370px;
  }
  h3 {
    font-size: 18px;
    line-height: 20px;
  }
  .ElWell_img {
    padding: 15px 10px 15px 20px;
  }

  .trubka {
    top: 140px;
  }
  .message h3 + p {
    line-height: 22px;
  }
  .price {
    font-size: 30px;
  }
  .criptaName {
    font-size: 30px;
    line-height: 16px;
  }

  .well {
    padding-bottom: 50px;
  }
  .mediaBlock h1 {
    font-size: 18px;
  }
  .mediaBlock p.mini {
    font-size: 25px;
  }
  .mediaBlock p.mini {
    margin: 0px 0px 11px 0px;
    line-height: 25px;
  }
  .mediaBlock p.big {
    font-size: 45px;
    line-height: 67px;
  }
  .mediaBlock .media {
    position: absolute;
    bottom: -4px;
    width: 75%;
    right: 0;
  }
  .button {
    font-size: 16px;

    padding: 33px 22px;
  }
  .listServices .service p {
    font-size: 14px;
    line-height: 16px;
  }
  .listServices .service .head {
    margin-bottom: 10px;
  }
  .tre p {
    font-size: 16px;
    line-height: 22px;
  }
  .tre .media {
    height: 50%;
    padding: 30px;
  }
  .block3 .interes .tre p {
    min-height: 100px;
    padding: 20px;
  }
  .tre img {
    width: auto;
    height: 151px;
    padding: 14px;
  }
  .block4 h2,
  .block5 h2 {
    padding: 20px 7px;
    font-size: 35px;
    text-align: center;
  }
  .block4 h2 br {
    display: none;
  }
  .block5 p {
    font-size: 29px;
    line-height: 104%;
  }
  .kontaktDetail {
    margin-bottom: 0;
  }
  .message {
    padding: 20px;
    height: auto;
  }
}

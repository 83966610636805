.comments{
    padding: 100px 0px;
    background-color: #FAF8F2;
    background-image: url(../../public/img/fon.png);
    /* mix-blend-mode: soft-light; */
    background-size: cover;
}
.comments h2{
    padding: 0px 70px;
}
.comments_box{
    display: flex;
    justify-content: space-between;
}
.message_shedow{
    width: 30%;
    filter: drop-shadow(0px 4px 4px #000000);
}
.blockWindow{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.message{
    background-color: #fff;
    padding: 40px;
    width: 100%;
    display: flex;
    height: 550px;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    clip-path: polygon(0.00% 0.00%,100.00% 0.00%,100.00% 100%,5.54% 98.62%,3.33% 98.35%,1.54% 97.9%,0% 96.76%);

}
.message h3+p{
    font-size: 25px;
    line-height: 25px;
    justify-content: normal;
    justify-self: auto;
    height: 100%;
    padding: 20px 0px;
}
.message_footer{
    display: flex;
    justify-content: space-between;
}
.message_footer img{
    width: auto;
    height: 30px;
}

.data_box{
    display: flex;
    gap: 10px;
}
.header {
    padding: 5px 0px;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 99999;
    transition: 0.3s;
}

.header .logo {
    width: 140px;
    transition: 0.3s;
}

.header .logo img {
    width: 140px;
    transition: 0.3s;
}

.mobileBlock {
    width: 90%;
}

.lang {
    width: 80px;
}

nav {
    width: 100%;
    text-align: center;
    width: 1000px;
    font-size: 20px;
    text-transform: uppercase;
    color: #6A0E1C;
}


nav a {
    color: #6A0E1C;
    margin: 0px 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
}

nav a:hover {
    color: #1E1A15;
    text-decoration-color: #E18C44;
    text-decoration-line: underline;
}

.buttonBlock {
    width: 345px;
}

.langs {
    font-family: 'Exo 2';
    text-transform: uppercase;
    font-size: 20px;
    color: #777;
}

.langs .lang {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}

.langs .lang.active {
    border: 1px solid #6A0E1C;
    color: #6A0E1C;
}

.header .button {
    padding: 15px 40px;
    text-align: center;
    font-family: 'Exo 2';
    font-size: 16px;
}


.header.scrol {
    background-color: #fff;
    box-shadow: 0px 0px 10px #000;
}

.header.scrol .logo img {
    width: 60px;
}

/* Адаптивное меню */
.media_menu {
    display: none;
}

.burger-menu_button {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 30;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid #000000;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.burger-menu_button:hover .burger-menu_lines {
    filter: brightness(0.7);
}

.burger-menu_button:hover {
    background-color: rgba(255, 255, 255, 0.7);
}

.burger-menu_lines::before,
.burger-menu_lines::after,
.burger-menu_lines {
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #6A0E1C;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.burger-menu_lines {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.media_menu_link{
    padding-top: 43px;

    z-index: -1;
    position: fixed;
    width: 100%;
    height: 100vh;
    right:0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #fff;
    justify-content: space-around;
    transition: 300ms;
    overflow: hidden;
}


.burger-menu_lines::before {
    content: '';
    top: -12px;
}

.burger-menu_lines::after {
    content: '';
    top: 12px;
}


.burger-menu_active .burger-menu_lines {
    background-color: transparent;
}

.burger-menu_active .burger-menu_lines::before {
    top: 0;
    transform: rotate(45deg);
}

.burger-menu_active .burger-menu_lines::after {
    top: 0;
    transform: rotate(-45deg);
}
/* footer */
.footer{
	background: #000;
	padding: 10px;
	color: #fff;
}

.footer .logo{
	width: 50px;
}

.footer a{
	margin: 10px;
	color: #fff;
	font-size: 20px;
}

.footer .prava{
	width: 200px;
	text-align: right;
}
/* well */
.ElWell{
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    height: fit-content;
    width: auto;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    clip-path: polygon(0.00% 0.00%,100.00% 0.00%,100.00% 100%,8.28% 90.11%,6.03% 89.79%,3.76% 88.51%,1.51% 86.93%,0.33% 82.41%,0.00% 76.14%);
}
.shadow{
    filter: drop-shadow(0px 4px 4px #000000);
    
}
.well .container{
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 20px;
}
.criptaName{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 47px;
}
.price{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 52px;
    color: #1E1A15;
}
.ElWell_img{
    width: 103px;
    height: 103px;
    padding: 10px;
}
.well {
    padding-bottom: 100px;
}
.ElWell div {
    padding: 23px 10px;
    
}
/* main */
.mainBlock{
	width: 100%;
	position: relative;
	margin: 0px;
	padding: 120px 0px 0px 0px;
	background: url(../../public/img/fon_main.jpg) no-repeat;
	background-size: cover;
	background-position: center center;
}

.mediaBlock{
}


.mediaBlock .media{
	width: 50%;
	
}
.tre {
	clip-path: polygon(0.00% 0.00%,100.00% 0.00%,100.00% 100%,5.54% 98.62%,3.33% 98.35%,1.54% 97.9%,0% 96.76%);
}
.tre .media{
	background: url(../../public/img/fon_block3.jpg) no-repeat;
    background-position: center;
    background-size: cover;
	height: 400PX;
	display: flex;
    justify-content: center;
    align-items: center;
}
.mediaBlock .content{
	width: 50%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: 100px 0px;
}

.mediaBlock h1{
	font-family: "Roboto";
    font-size: 24px;
    color: #000;
    width: 100%;
    text-transform: uppercase;
}

.mediaBlock p.big{
	font-family: 'Exo 2';
	font-size: 150px;
	line-height: 120px;
	width: 100%;
	margin: 40px 0px;
}

.mediaBlock p.mini{
	font-size: 30px;
	font-family: "Roboto";
	margin: 30px 0px 40px 0px;
	line-height: 30px;
}





.block2{
	padding: 100px 0px;
	background-color: #FAF8F2;
	background-image: url(../../public/img/fon.png) ;
	/* mix-blend-mode: soft-light; */
	background-size: cover;
}

.listServices{
	margin: 30px 0px;
	flex-wrap: wrap;
}

.listServices .service{
	width: 45%;
	margin: 20px 0px;
}

.listServices .service img{
	height: 70px;
	width: auto;
	margin-right: 10px;
}

.listServices .service .head{
	margin-bottom: 20px;
}

.listServices .service p{
	font-size: 25px;
	color: #000;
}

.block3 {
	padding: 50px 0px;
}

.block3 .interes {
	margin-top: 50px;
}

.block3 .interes .tre {
    width: 100%;
	background: #FAF8F2;
    
}
.tre img{
	width: 50%;
	height: auto;
}
.tre_show{
	width: 30%;
	font-size: 25px;
	filter: drop-shadow(0px 4px 4px #000000);
    border-radius: 10px;
    overflow: hidden;
}
.block3 .interes .tre p {
    min-height: 200px;
    padding:20px;
}


.block4{
	background: url(../../public/img/fon_block4.jpg) no-repeat;
	background-size: cover;
	background-position: center center;
	position: relative;
}
.trubka{
	position: absolute;
	top: 60px;
	right: 0;
	width: 225px;
	height: auto;
}
.kontaktDetail {
    margin-bottom: 60px;
}

.block4 .media{
	width: 60%;
	padding-top: 50px;
}
.block4 .media img{
	width: 80%;
	position: relative;
    bottom: -5px;
}

.block4 .content{
	width: 40%;
}

.block4 .content p{
	margin: 20px 20px;
	font-weight: 500;
	font-size: 30px;
	line-height: 125%;
	position: relative;
    z-index: 1;
}
.block4 h2{
    text-align: end;
    font-size: 112px;
    line-height: 111.02%;

}
.chat{
	box-sizing: border-box;

	color: #000;
	background: #FFFFFF;
	border-radius: 20px;
	position: relative;
	padding: 14px 10px;
	filter: drop-shadow(-4px 5px 4px #000000);
}
.chat::after{
	content: "";
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    left: -34px;
    top: 39%;
}



.block5{
	padding: 30px 0px;
	position: relative;
}

.block5 .bigText{
	font-size: 300px;
	line-height: 250px;
	color: #eee;
	position: absolute;
    font-family: 'Exo 2';
    z-index: -1;
}
.block5 .content{
	width: 40%;
}

.block5 .media{
	width: 50%;
}


.block5 h2{
	font-size: 100px;
}

.block5 p{
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 125%;
	color: #000000;
}



.block6{
	background: url(../../public/img/fon_block6.png) no-repeat;
	background-size: cover;
	background-position: top center;
	padding: 60px 0px 120px 0px;
}
.block6 h2{
	color: #fff;
}
.block6 p{
	margin-bottom: 70px;
	mask-type: 20px;
	position: relative;
}
.block6 .chat::after{
    content: "";
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    left: -24px;
    top: 25%;
}
.block6 .contentLeft{
	width: 40%;
	color: #fff;
}
.block6 .flex {
    align-items: flex-start;
}
.block6 .button {
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    color: #000000;
	background-image: url(../../public/img/yellow_button.svg);
    filter: drop-shadow(-2px 3px 1px #000);
}
.block6 .contentRight{
    padding-top: 60px;
    width: 40%;
    text-align: right;
}
.contentRight h3{
	font-style: normal;
	font-weight: 700;
    font-size: 100px;
    line-height: 75px;
	text-align: right;
	position: relative;
    right:0px;
}
.block6 p.info{
	margin: 20px 0px;
	padding: 20px 30px;
	background-color: #fff;
	border-radius: 20px;
	text-align: left;
}
.block6{
	position: relative;
}
.men{
	position: absolute;
	bottom:0px;
	right: 0px;
	width: 485px;
	height: auto;
}
.contentRight_info_box{
	position: relative;
    right: 158px;
    bottom: -15px;
}


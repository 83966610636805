
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Roboto';
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex.left {
  justify-content: flex-start;
}

.flex.right {
  justify-content: flex-end;
}

.flex.top {
  align-items: flex-start;
}

.flex.bottom {
  align-items: flex-end;
}

body {
  font-family: "Roboto";
  margin: 0px;
  padding: 0px;
  color: #6A0E1C;
  background: #FAF8F2;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  padding: 0px;
  font-weight: 700;
}

h2 {
  font-size: 100px;
  line-height: 75px;
  margin-bottom: 50px;
}

h3 {
  font-size: 35px;
  line-height: 35px;
}

.container {
  width: 1500px;
  margin: 0px auto;
}

a {
  text-decoration: none;
  color: auto;
}

img {
  width: 100%;
}

p {
  font-size: 25px;
  line-height: 25px;
}

.button {
  padding: 36px 42px;

  text-align: center;
  color: #fff;
  width: auto;
  text-align: center;
  text-transform: uppercase;
  border: 0px solid #000;
  background-image: url(../../public/img/red_button.svg);
  filter: drop-shadow(-2px 3px 1px #000);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 5px;
  font-family: 'Exo 2';
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s;
  background-color: #ffffff00;
  position: relative;


}

.button:hover {
  filter: drop-shadow(-3px 5px 7px #000);
}
@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-BlackItalic.eot');
    src: local('Exo 2 Black Italic'), local('Exo2-BlackItalic'),
        url('Exo2-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-BlackItalic.woff2') format('woff2'),
        url('Exo2-BlackItalic.woff') format('woff'),
        url('Exo2-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2 Extra';
    src: url('Exo2-ExtraLightItalic.eot');
    src: local('Exo 2 Extra Light Italic'), local('Exo2-ExtraLightItalic'),
        url('Exo2-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-ExtraLightItalic.woff2') format('woff2'),
        url('Exo2-ExtraLightItalic.woff') format('woff'),
        url('Exo2-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Black.eot');
    src: local('Exo 2 Black'), local('Exo2-Black'),
        url('Exo2-Black.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Black.woff2') format('woff2'),
        url('Exo2-Black.woff') format('woff'),
        url('Exo2-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2 Extra';
    src: url('Exo2-ExtraBoldItalic.eot');
    src: local('Exo 2 Extra Bold Italic'), local('Exo2-ExtraBoldItalic'),
        url('Exo2-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-ExtraBoldItalic.woff2') format('woff2'),
        url('Exo2-ExtraBoldItalic.woff') format('woff'),
        url('Exo2-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Regular.eot');
    src: local('Exo 2'), local('Exo2-Regular'),
        url('Exo2-Regular.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Regular.woff2') format('woff2'),
        url('Exo2-Regular.woff') format('woff'),
        url('Exo2-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-BoldItalic.eot');
    src: local('Exo 2 Bold Italic'), local('Exo2-BoldItalic'),
        url('Exo2-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-BoldItalic.woff2') format('woff2'),
        url('Exo2-BoldItalic.woff') format('woff'),
        url('Exo2-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-ThinItalic.eot');
    src: local('Exo 2 Thin Italic'), local('Exo2-ThinItalic'),
        url('Exo2-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-ThinItalic.woff2') format('woff2'),
        url('Exo2-ThinItalic.woff') format('woff'),
        url('Exo2-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2 Semi';
    src: url('Exo2-SemiBold.eot');
    src: local('Exo 2 Semi Bold'), local('Exo2-SemiBold'),
        url('Exo2-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Exo2-SemiBold.woff2') format('woff2'),
        url('Exo2-SemiBold.woff') format('woff'),
        url('Exo2-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2 Extra';
    src: url('Exo2-ExtraBold.eot');
    src: local('Exo 2 Extra Bold'), local('Exo2-ExtraBold'),
        url('Exo2-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Exo2-ExtraBold.woff2') format('woff2'),
        url('Exo2-ExtraBold.woff') format('woff'),
        url('Exo2-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-MediumItalic.eot');
    src: local('Exo 2 Medium Italic'), local('Exo2-MediumItalic'),
        url('Exo2-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-MediumItalic.woff2') format('woff2'),
        url('Exo2-MediumItalic.woff') format('woff'),
        url('Exo2-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2 Semi';
    src: url('Exo2-SemiBoldItalic.eot');
    src: local('Exo 2 Semi Bold Italic'), local('Exo2-SemiBoldItalic'),
        url('Exo2-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-SemiBoldItalic.woff2') format('woff2'),
        url('Exo2-SemiBoldItalic.woff') format('woff'),
        url('Exo2-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Medium.eot');
    src: local('Exo 2 Medium'), local('Exo2-Medium'),
        url('Exo2-Medium.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Medium.woff2') format('woff2'),
        url('Exo2-Medium.woff') format('woff'),
        url('Exo2-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-LightItalic.eot');
    src: local('Exo 2 Light Italic'), local('Exo2-LightItalic'),
        url('Exo2-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-LightItalic.woff2') format('woff2'),
        url('Exo2-LightItalic.woff') format('woff'),
        url('Exo2-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Thin.eot');
    src: local('Exo 2 Thin'), local('Exo2-Thin'),
        url('Exo2-Thin.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Thin.woff2') format('woff2'),
        url('Exo2-Thin.woff') format('woff'),
        url('Exo2-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Italic.eot');
    src: local('Exo 2 Italic'), local('Exo2-Italic'),
        url('Exo2-Italic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Italic.woff2') format('woff2'),
        url('Exo2-Italic.woff') format('woff'),
        url('Exo2-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2 Extra';
    src: url('Exo2-ExtraLight.eot');
    src: local('Exo 2 Extra Light'), local('Exo2-ExtraLight'),
        url('Exo2-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Exo2-ExtraLight.woff2') format('woff2'),
        url('Exo2-ExtraLight.woff') format('woff'),
        url('Exo2-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Light.eot');
    src: local('Exo 2 Light'), local('Exo2-Light'),
        url('Exo2-Light.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Light.woff2') format('woff2'),
        url('Exo2-Light.woff') format('woff'),
        url('Exo2-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Bold.eot');
    src: local('Exo 2 Bold'), local('Exo2-Bold'),
        url('Exo2-Bold.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Bold.woff2') format('woff2'),
        url('Exo2-Bold.woff') format('woff'),
        url('Exo2-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

